import img1 from './resourses/game0.jpg';
import img2 from './resourses/game1.jpg';
import img3 from './resourses/game2.jpg';
import img4 from './resourses/game3.jpg';
import img5 from './resourses/game4.jpg';
import img6 from './resourses/game5.jpg';

const CONSTANTS = {
    pageTwoSectionOneImgs: [img1, img2, img3, img4, img5, img6],
    links: {
        telegram: 'https://t.me/+UnB8rVgbikhiNmVl',
        X: 'https://x.com/RatsgameXYZ'
    },
    timeline: [
        {
            title: '2024 Aug',
            cardTitle: 'Launch First Game: Whac-A-Mole',
            content: ["Release the first game on the RatsGame platform, Whac-A-Mole, to engage users and introduce them to the platform's capabilities."]
        },
        {
            title: '2024 Sep',
            cardTitle: 'Introduce RatsGame Launchpool',
            content: ["Launch RatsGame Launchpool, allowing users to stake their characters and mine governance token $RATS Score. This feature aims to enhance user engagement and provide additional earning opportunities."]
        },
        {
            title: '2024 Dec',
            cardTitle: 'Platform Launch and $RATS Token Release',
            content: ["Officially launch the RatsGame platform.",
                "Release the $RATS token to the public, enabling trading and utilization within the platform."
            ]
        },
        {
            title: '2025',
            cardTitle: 'The Future plan...',
            content: []
        }
    ]
};

export default CONSTANTS;