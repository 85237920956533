import './App.css';
import { useState, useRef, useEffect } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Scrollbar, Mousewheel } from 'swiper/modules';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/mousewheel';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import PageOne from './pages/pageOne';
import PageTwo from './pages/pageTwo/pageTwo';
import PageThree from './pages/pageThree';
import logo from './resourses/logo.png';
import TelegramIcon from '@mui/icons-material/Telegram';
import XIcon from '@mui/icons-material/X';
import IconButton from '@mui/material/IconButton';
import CONSTANTS from './constants';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import ShareIcon from '@mui/icons-material/Share';

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(250, 101, 46)'
    }
  },
});

const App = () => {
  const swiperRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    swiperRef.current.swiper.on('slideChange', function () {
      setCurrentPage(swiperRef.current.swiper.activeIndex);
    });
  }, [swiperRef])

  return (
    <ThemeProvider theme={theme}>
      <div className='App'>
        <div id='sharePanel'>
          <div id='sharePanelLandscape'>
            <IconButton aria-label="telegram" onClick={() => window.open(CONSTANTS.links.telegram, "_blank")}>
              <TelegramIcon style={{ color: currentPage === 1 ? 'rgb(255, 255, 255)' : 'rgb(250, 101, 46)' }} fontSize='large' />
            </IconButton>
            <IconButton aria-label="X" onClick={() => window.open(CONSTANTS.links.X, "_blank")}>
              <XIcon style={{ color: currentPage === 1 ? 'rgb(255, 255, 255)' : 'rgb(250, 101, 46)' }} fontSize='large' />
            </IconButton>
          </div>
          <div id='sharePanelPortrait'>
            <SpeedDial ariaLabel="share small panel"
              sx={{ position: 'absolute', right: -40, top: -10}}
              FabProps={{ size: 'small' }} icon={<ShareIcon />} direction='down'>
              <SpeedDialAction key={'telegram'} icon={<TelegramIcon />} tooltipTitle={'telegram'} onClick={() => window.open(CONSTANTS.links.telegram, "_blank")} />
              <SpeedDialAction key={'X'} icon={<XIcon />} tooltipTitle={'X'} onClick={() => window.open(CONSTANTS.links.X, "_blank")}/>
            </SpeedDial>
          </div>
        </div>
        <div id='nav'>
          <img id='navLogo' src={logo} alt="nav-logo" />
          <Button variant="text" style={{ borderRadius: 50, height: '30px', marginRight: '20px' }}><span className='nav-button-text'>Home</span></Button>
          <Button variant="text" style={{ borderRadius: 50, height: '30px', marginRight: '20px' }}><span className='nav-button-text'>Games</span></Button>
          <Button variant="text"
                  style={{ borderRadius: 50, height: '30px', marginRight: '20px' }}
                  onClick={() => window.open('https://docsend.com/view/ji8deebd9jngafe9', '_blank')}
          >
            <span className='nav-button-text'>White Paper</span>
          </Button>
        </div>
        <div id='mainApp'>
          <Swiper
            direction={'vertical'}
            mousewheel={{
              enabled: true,
            }}
            modules={[Scrollbar, Mousewheel]}
            ref={swiperRef}
          >
            <SwiperSlide><PageOne /></SwiperSlide>
            <SwiperSlide><PageTwo /></SwiperSlide>
            <SwiperSlide><PageThree /></SwiperSlide>
          </Swiper>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default App;
