import '../../App.css';
import { useState, useRef, useEffect } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Autoplay } from 'swiper/modules';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/pagination';

import PageTwoSectionOne from './pageTwoSectionOne';
import PageTwoSectionTwo from './pageTwoSectionTwo';


const PageTwo = () => {

    const pagination = {
        type: 'custom',
        renderCustom: (swiper, current, total) => {
            return '';
        }
    };

    return (
        <div id='pageTwo'>
            <Swiper
                pagination={pagination}
                navigation={true}
                modules={[Pagination, Autoplay]}
                className="swiperTwo"
                autoplay={{
                    delay: 5000
                }}
            >
                <SwiperSlide><PageTwoSectionOne /></SwiperSlide>
                <SwiperSlide><PageTwoSectionTwo /></SwiperSlide>
            </Swiper>
        </div>
    );
}

export default PageTwo;
