import logoText from '../../resourses/logo-text.png';
import Button from '@mui/material/Button';
import sectwoimg from '../../resourses/pagetwpsectwo.png';


const PageTwoSectionTwo = () => {

    return (
        <div id='pageTwoSectionTwo' className="page-two-section">
            <div className='responsive-full-half center' style={{ flexDirection: 'column', alignItems: 'flex-start', marginLeft: '80px' }}>
                <img className='section-two-logo' src={logoText} alt="logo-text" />
                <span className='section-two-title'>Telegram Native Games</span>
                <span className='section-two-des'>Where every game leads to an airdrop adventure Play and trade using TG Game Bot</span>
                <Button variant="contained" className='section-two-button' style={{ borderRadius: 50, width: '180px', marginTop: '12px' }}><span className='button-text'>Explor Games</span></Button>
            </div>
            <div className='responsive-full-half center'>
                <img id='secTwoImg' src={sectwoimg} alt="logo-text" />
            </div>
        </div>
    );
}

export default PageTwoSectionTwo;
