import logoText from '../resourses/logo-text.png';
import imageOne from '../resourses/dancingRat.gif';
import telegramImg from '../resourses/telegramappscenter.png';
import Button from '@mui/material/Button';
import { useState } from "react";


const PageOne = () => {

    const [userData, setUserData] = useState([{
        display: 'Users',
        value: 0
    }, {
        display: 'Daily Users',
        value: 0
    }, {
        display: 'Onchain Users',
        value: 0
    }, {
        display: 'Onchain Txs',
        value: 0
    }, {
        display: 'X Followers',
        value: 0
    }, {
        display: 'Channel subscribers',
        value: 0
    }])

    return (
        <div id='pageOne'>
            <div className="container">
                <div className='inner-container'>
                    <div id='page-one-section-one' className='page-one-section' style={{ flexDirection: 'column' }}>
                        <img className='margin' id='mainLogo' src={logoText} alt="logo-text" />
                        <span className='title' style={{
                            textShadow: '3px 3px rgb(255, 166, 83)',
                            color: 'rgb(250, 101, 46)',
                        }}>Rats' VIBE: MEME THE WORLD</span>
                        <div className='button-container center' style={{ justifyContent: 'flex-start', marginTop: '12px' }}>
                            <div className='margin-small' style={{ marginLeft: 0 }}>
                                <Button variant="contained" className='page-one-button' style={{ borderRadius: 50, width: '180px' }}><span className='button-text'>@RatsBot</span></Button>
                            </div>
                            <div className='margin-small'>
                                <Button variant="contained" className='page-one-button' style={{ borderRadius: 50, width: '180px' }}><span className='button-text'>@Rats_Another_Bot</span></Button>
                            </div>
                        </div>
                        <img className='margin' style={{ width: '180px', height: 'auto' }} src={telegramImg} alt="telegram" />
                    </div>
                    <div id='page-one-section-two' className='center page-one-section'>
                        <img id='mainGif' src={imageOne} alt="dancingcat" />
                    </div>
                </div>
                <div id='user-date-grid'>
                    {userData.map((data, i) => (<div key={i} className='data-grid-item'>
                        <span className='data-grid-item-title'>{data.display}</span>
                        <span className='data-grid-item-value'>{data.value.toLocaleString()}</span>
                    </div>))}
                </div>
            </div>
        </div>
    );
}

export default PageOne;