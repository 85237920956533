import '../App.css';
import { useState, useRef, useEffect } from "react";
import { Chrono } from "react-chrono";
import CONSTANTS from '../constants';
import rocket from '../resourses/rocket.png';


const PageThree = () => {

    const items = CONSTANTS.timeline.map(i => {
        return { title: i.title, cardTitle: i.cardTitle }
    });

    return (
        <div id='pageThree'>
            <div className='timeline-headline'>
                <img className='timeline-headline-img' src={rocket} alt="rocket" />
                <span className='timeline-headline-title'>Roadmap</span>
            </div>
            <div className='timeline-xl' style={{ width: '1500px', height: '70%' }}>
                <Chrono
                    showAllCardsHorizontal={true}
                    mode="HORIZONTAL"
                    items={items}
                    disableToolbar={true}
                    cardWidth={330}
                    itemWidth={350}
                    cardHeight={500}
                    classNames={{
                        title: 'time-line-card-title',
                        cardTitle: 'time-line-sub-title'
                    }}
                    theme={{
                        primary: '#fa7e38',
                        secondary: '#f5e342',
                        cardBgColor: 'white',
                        titleColor: '#fa7e38',
                        titleColorActive: '#fa7e38',
                    }}
                >
                    {CONSTANTS.timeline.map((i, index) => (
                        <div key={index + 'row'} style={{ display: 'flex', flexDirection: 'column' }}>
                            {i.content.map(c => <span key={c} className='time-line-card-content'>{c}</span>)}
                        </div>
                    ))}
                </Chrono>
            </div>
            <div className='timeline-md' style={{ width: '1000px', height: '70%' }}>
                <Chrono
                    showAllCardsHorizontal={true}
                    mode="HORIZONTAL"
                    items={items}
                    disableToolbar={true}
                    cardWidth={212}
                    itemWidth={240}
                    cardHeight={500}
                    classNames={{
                        title: 'time-line-card-title',
                        cardTitle: 'time-line-sub-title'
                    }}
                    theme={{
                        primary: '#fa7e38',
                        secondary: '#f5e342',
                        cardBgColor: 'white',
                        titleColor: '#fa7e38',
                        titleColorActive: '#fa7e38',
                    }}
                >
                    {CONSTANTS.timeline.map((i, index) => (
                        <div key={index + 'row'} style={{ display: 'flex', flexDirection: 'column' }}>
                            {i.content.map(c => <span key={c} className='time-line-card-content'>{c}</span>)}
                        </div>
                    ))}
                </Chrono>
            </div>
            <div className='timeline-sm' style={{ width: '100%', height: '85%' }}>
                <Chrono
                    // showAllCardsHorizontal={true}
                    mode="VERTICAL"
                    // items={items}
                    disableToolbar={true}
                    cardWidth={270}
                    // itemWidth={350}
                    cardHeight={140}
                    classNames={{
                        title: 'time-line-card-title',
                        cardTitle: 'time-line-sub-title'
                    }}
                    theme={{
                        primary: '#fa7e38',
                        secondary: '#f5e342',
                        cardBgColor: 'white',
                        titleColor: '#fa7e38',
                        titleColorActive: '#fa7e38',
                    }}
                >
                    {CONSTANTS.timeline.map((i, index) => (
                        <div key={index + 'row'} style={{ display: 'flex', flexDirection: 'column' }}>
                            <div className='time-line-card-title'>{i.title}</div>
                            <div className='time-line-sub-title'>{i.cardTitle}</div>
                            <div style={{marginTop: '-20px'}}>
                                {i.content.map(c => <div key={c} className='time-line-card-content'>{c}</div>)}
                            </div>
                        </div>
                    ))}
                </Chrono>
            </div>
        </div>
    );
}

export default PageThree;
