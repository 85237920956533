import logoText from '../../resourses/logo-text.png';
import Button from '@mui/material/Button';

import CONSTANTS from '../../constants';


const PageTwoSectionOne = () => {

    return (
        <div id='pageTwoSectionOne' className="page-two-section">
            <div className='responsive-full-half center' style={{ flexDirection: 'column', alignItems: 'flex-start', marginLeft: '80px' }}>
                <img className='section-two-logo' src={logoText} alt="logo-text" />
                <span className='section-two-title'>Mini Game Platform</span>
                <span className='section-two-des'>Where every game leads to an airdrop adventure Play and trade using TG Game Bot</span>
                <Button variant="contained" className='section-two-button' style={{ borderRadius: 50, width: '180px', marginTop: '12px' }}><span className='button-text'>Explor Games</span></Button>
            </div>
            <div className='responsive-full-half center'>
                <div id='pageTwoGameGallery'>
                    {CONSTANTS.pageTwoSectionOneImgs.map((img, i) => (
                        <div key={`section-one-img-${i}`} className='game-gallery-icon'>
                            <img src={img} />
                        </div>
                    ))}
                </div>

            </div>
        </div>
    );
}

export default PageTwoSectionOne;
